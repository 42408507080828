@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color:  #fff;
}


.swiper-button-next:after,
.swiper-button-prev:after {
    color: #202A54
}

.swiper-pagination-progressbar .swiper-pagination-progessbar-fill{
    background: #202A54 !important;
    
}

html::-webkit-scrollbar{
    display: none;
}

html{
    scrollbar-width: none;
}
.menu-x li:hover{
    color: #F6D200 !important;
}